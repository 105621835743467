/*
	Fluid Property
	http://www.adrenalinmedia.com.au/the-agency/insights/this-changes-everything-css-fluid-properties.aspx
	HTML:
	<h1 class="element">
		<span>Text to replace</span>
	</h1>
	example:
	h1 {
		@include fp(font-size, 50, 100); // 50px at 320, 100px at 1920;
	}
	output:
	h1 {
		font-size: calc(3.125vw + 40px); //This is the magic!
	}
	@media (max-width:320px){ //Clips the start to the min value
		font-size:50px;
	}
	@media (min-width:1920px){ //Clips the end to the max value
		font-size:100px;
	}
*/

@mixin fp(
  $property,
  $min,
  $max,
  $start: 320,
  $end: 1200,
  $clip: true,
  $clipAtStart: true,
  $clipAtEnd: true
) {
  $start: $start / ($start * 0 + 1);
  $end: $end / ($end * 0 + 1);
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      /* stylelint-disable-next-line length-zero-no-unit */
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      /* stylelint-disable-next-line length-zero-no-unit */
      #{$property}: $max + 0px;
    }
  }

  #{$property}: $formula;
}
