@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.button {
  @include reset-btn;

  $block-name: &;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  max-width: 100%;
  height: 56px;
  padding: 4px 42px;
  transition: all $transition-time;
  font-size: $font-size-s;
  font-family: $font-family--headings;
  font-style: normal;
  line-height: $line-height-2xs;
  text-align: center;
  border-radius: $border-radius-s;
  background-color: $color-main;
  font-weight: $font-weight-xl;
  color: $white-100;

  > span {
    @include ellipsis(100%);
  }

  > svg {
    flex: 0 0 auto;
    width: 1.4em;
    height: 1.4em;

    &.chevron-right,
    &.chevron-left,
    &.arrow-right,
    &.arrow-left {
      stroke: currentColor;
      stroke-width: 1.5px;
    }

    &:first-child {
      margin-right: 0.6em;
      margin-left: -0.6em;
    }

    &:last-child {
      margin-left: 0.6em;
      margin-right: -0.6em;
    }

    path {
      stroke: currentColor;
      stroke-width: 1.5px;
    }

    &.dribbble-arrow {
      color: #f36999;
      background-color: $white-100;
      border-radius: 50%;
    }
  }

  &:hover {
    background-color: $white-100;
    color: $color-main;
  }

  &:active {
    background-color: $color-main;
    color: $white-100;
  }

  &#{$block-name} {
    &--disabled {
      background-color: $color-main !important;
      color: $white-100 !important;
    }
  }

  &--variant-secondary {
    background-color: $color-secondary;
    color: $white-100;

    &:hover {
      background-color: $green-500;
      color: $white-100;
    }

    &:active {
      background-color: $color-secondary;
      color: $white-100;
    }

    &#{$block-name} {
      &--disabled {
        color: $white-100 !important;
        background-color: $color-secondary !important;
      }
    }
  }

  &--variant-white {
    background-color: $white-200;
    color: $purple-500;

    &:hover {
      background-color: $purple-100;
      color: $purple-500;
    }

    &:active {
      background-color: $white-200;
      color: $purple-500;
    }

    &#{$block-name} {
      &--disabled {
        background-color: $white-200 !important;
        color: $purple-500 !important;
      }
    }
  }

  &--variant-main-outline {
    background-color: transparent !important;
    border: $border-main;
    color: $color-main;
    transition: none;

    &:hover {
      border-color: $purple-400;
      color: $purple-400;
    }

    &:active {
      border-color: $border-color-main;
      color: $color-main;
    }

    &#{$block-name} {
      &--disabled {
        background-color: transparent !important;
        border: $border-main !important;
        color: $color-main !important;
      }
    }
  }

  &--variant-white-outline {
    background-color: transparent !important;
    border: $border-third;
    color: $purple-100;

    &:hover {
      opacity: $opacity;
      border: $border-third;
      color: $purple-100;
    }

    &:active {
      border: $border-third;
      color: $purple-100;
      opacity: 1;
    }

    &#{$block-name} {
      &--disabled {
        opacity: 1;
      }
    }
  }

  &--variant-white-outline-ghost {
    border: none !important;
    background-color: transparent !important;
    color: $purple-100;

    &:hover {
      opacity: $opacity;
      color: $purple-100;
    }

    &:active {
      opacity: 1;
    }

    &#{$block-name} {
      &--disabled {
        opacity: 1;
      }
    }
  }

  &--variant-main-ghost {
    background-color: transparent !important;
    color: $color-main;
    transition: none;

    &:hover {
      color: $purple-400;
    }

    &:active {
      color: $color-main;
    }

    &#{$block-name} {
      &--disabled {
        background-color: transparent !important;
        color: $color-main !important;
      }
    }
  }

  &--variant-gray-outline {
    background-color: transparent !important;
    border: 1px solid $light-gray-200;
    color: $color-main;
    transition:
      border-color $transition-time,
      color $transition-time;
    border-radius: $border-radius-s;

    &:hover {
      border-color: $purple-400;
      color: $purple-400;
    }

    &:active {
      border-color: $light-gray-200;
      color: $color-main;
    }

    &#{$block-name} {
      &--disabled {
        background-color: transparent !important;
        border: $border-main !important;
        color: $color-main !important;
      }
    }
  }

  &--variant-main-ghost-hidden-padding {
    padding: 0 !important;
    transition: none;
    background-color: transparent !important;
    color: $color-main;

    &#{$block-name} {
      &--disabled {
        background-color: transparent !important;
        color: $color-main !important;
      }
    }

    &:hover {
      color: $purple-400;
    }

    &:active {
      color: $color-main;
    }
  }

  &--hover-variant-purple-light {
    &:hover {
      transition: all $transition-time !important;
      background-color: $purple-400 !important;
      border-color: $purple-400;
      color: $white-100;
      opacity: 1;
    }

    &:active {
      background: $color-main !important;
    }

    &#{$block-name} {
      &--disabled {
        // transition: all $transition-time !important;
        // background-color: $purple-400 !important;
        // border-color: $purple-400;
        // color: $white-100;
        // opacity: 1;
      }
    }
  }

  &--size-s {
    height: 38px;
    padding: 4px 22px;
    border-radius: $border-radius-xs;
    font-size: $font-size-3xs;
    line-height: $line-height-xs;
  }

  &--size-m {
    height: 45px;
    padding: 4px 30px;
    border-radius: $border-radius-s;
    font-size: $font-size-2xs;
    line-height: $line-height-xs;
  }

  &--size-l {
    height: 51px;
    padding: 4px 36px;
    border-radius: $border-radius-s;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
  }

  &--is-squared {
    align-items: center !important;
    justify-content: center !important;
    padding: 5px !important;

    > span {
      display: none !important;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    > svg {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &#{$block-name} {
      &--size-s {
        width: 38px !important;
      }

      &--size-m {
        width: 45px !important;
      }

      &--size-l {
        width: 51px !important;
      }

      &--size-xl {
        width: 56px !important;
      }
    }
  }

  &--full-width {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  &--disabled {
    cursor: not-allowed !important;
    opacity: 0.2 !important;
    pointer-events: none !important;
  }
}

@mixin neighbour-hover {
  width: 70px !important;
  background-color: $purple-400;

  &--size-s {
    width: 45px !important;
  }

  &--size-m {
    width: 55px !important;
  }

  &--size-l {
    width: 65px !important;
  }

  &--size-xl {
    width: 70px !important;
  }
}

@mixin neighbour-reset-hover {
  &--size-s {
    width: 38px !important;
  }

  &--size-m {
    width: 45px !important;
  }

  &--size-l {
    width: 51px !important;
  }

  &--size-xl {
    width: 56px !important;
  }
}

.button-neighbour-wrapper {
  $block-name: &;

  display: inline-flex;
  gap: 0 8px;

  @include mq($to: md) {
    display: flex;
  }

  .button {
    &:not(#{$block-name}__right, #{$block-name}__left) {
      @include mq($to: md) {
        flex-grow: 1;
        justify-content: center;
      }
    }
  }

  &__left,
  &__right {
    flex: 0 0 auto;
  }

  &--hover-with-in-right-neighbour {
    .button {
      &:not(#{$block-name}__right, #{$block-name}__left) {
        &:hover + .button {
          @include neighbour-hover;

          @include mq($to: md) {
            @include neighbour-reset-hover;
          }
        }

        &:active + .button {
          background: $color-main;
        }
      }
    }

    #{$block-name} {
      &__right {
        &:hover {
          @include neighbour-hover;

          @include mq($to: md) {
            &.button {
              @include neighbour-reset-hover;
            }
          }
        }

        &:active {
          background: $color-main;
        }
      }
    }
  }
}
