@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.clutch {
  @include reset-text-indents;

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  &__star_icon {
    color: #ff4d39;
  }
}
