@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.badge {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 11px;
  text-decoration: none;

  &--with-rating {
    gap: 9px;
  }

  & p {
    white-space: nowrap;
  }
}
