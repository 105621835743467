@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.section {
  &__header {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    align-items: center !important;
    padding: 100px 0 !important;
    gap: 16px !important;

    @include mq($to: xxl) {
      grid-template-columns: 1fr !important;
      padding: 60px 0 !important;
    }

    @include mq($to: sm) {
      grid-template-columns: 1fr !important;
      padding: 30px 0 !important;
    }
  }

  &__title {
    max-width: 700px !important;
  }

  &__list {
    display: flex !important;
    flex-flow: row wrap !important;
    margin: 0 !important;
    padding: 30px 0 60px !important;
    gap: 20px !important;
    list-style-type: none !important;

    @include mq($to: xxl) {
      gap: 18px !important;
    }

    // @include mq($to: lg) {
    //   gap: 18px;
    // }

    @include mq($to: sm) {
      padding: 0 0 60px !important;
    }
  }

  &__card {
    position: relative !important;
    width: calc(40% - 10px) !important;
    height: 280px !important;
    padding: 30px 36px !important;
    overflow: hidden !important;
    border-radius: $border-radius-2xl !important;
    text-decoration: none !important;

    > h3 {
      margin-bottom: 7px;
    }

    // @include mq($to: lg) {
    //   width: calc(50% - 9px) !important;
    //   height: unset;
    //   aspect-ratio: 1 / 0.9057;
    //   border-radius: $border-radius-l;
    // }

    @include mq($to: xxl) {
      width: calc(50% - 9px) !important;
      height: unset !important;
      aspect-ratio: 1 / 0.8 !important;
      border-radius: $border-radius-l !important;
    }

    @include mq($to: xl) {
      height: 346px !important;
    }

    @include mq($to: lg) {
      aspect-ratio: 1 / 0.9057 !important;
    }

    @include mq($to: 830px) {
      width: 100% !important;
      aspect-ratio: unset !important;
      height: 346px !important;
    }

    // @include mq($to: md) {
    //   width: 100% !important;
    //   aspect-ratio: unset;
    //   height: 346px;
    // }

    @include mq($to: sm) {
      // width: 100% !important;
      aspect-ratio: 0.9913 / 1 !important;
    }

    &--chain_stores {
      padding-right: 315px !important;
      width: calc(60% - 10px) !important;
      background: linear-gradient(99deg, #99f07d 0%, #cfffc0 100%) !important;

      @include mq($to: xxl) {
        padding-right: 30px !important;
        width: calc(50% - 9px) !important;
      }

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__image {
        position: absolute !important;
        top: -32% !important;
        right: -20% !important;

        @include mq($to: xxl) {
          top: unset !important;
          top: 30% !important;
          right: -3% !important;
          width: 416px !important;
          height: 416px !important;
        }

        @include mq($to: lg) {
          top: 48% !important;
          right: -3% !important;
          width: 340px !important;
          height: 340px !important;
        }

        // @include mq($to: xm) {
        // top: unset;
        // top: 23%;
        // right: -3%;
        // width: 399px;
        // height: 399px;
        // }
      }
    }

    &--suppliers {
      background: $purple-400 !important;

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__image {
        position: absolute !important;
        right: -4% !important;
        bottom: 10px !important;
        min-width: 538px !important;
        min-height: 88px !important;

        @include mq($to: xl) {
          left: 30px !important;
        }

        @include mq($to: 820px) {
          right: -25px !important;
          left: unset !important;
        }

        @include mq($to: sm) {
          right: unset !important;
          left: 30px !important;
        }
      }
    }

    &--online_sales_platform {
      background: $purple-400 !important;
      padding-right: 320px !important;
      width: calc(50% - 10px) !important;

      @include mq($to: xxl) {
        padding-right: 30px !important;
      }

      @include mq($to: 830px) {
        padding-right: 113px !important;
        width: 100% !important;
      }

      @include mq($to: 820px) {
        padding-right: 30px !important;
      }

      &__image {
        position: absolute !important;
        right: 0 !important;
        bottom: -47% !important;
        width: 364px !important;
        height: 409px !important;

        @include mq($to: xxl) {
          right: 0% !important;
          bottom: -128px !important;
        }

        @include mq($to: xl) {
          right: 50% !important;
          bottom: -200px !important;
          transform: translateX(50%) !important;
        }

        @include mq($to: lg) {
          // right: -1%;
          // bottom: 0;
        }

        @include mq($to: 830px) {
          right: 0 !important;
          bottom: -200px !important;
          transform: none !important;
        }

        @include mq($to: md) {
          // right: 0;
          // bottom: 0;
        }

        // @include mq($to: sm) {
        //   right: -15%;
        //   bottom: -13%;
        // }
      }
    }

    &--chain_restaurants {
      padding-right: 308px !important;
      width: calc(50% - 10px) !important;
      background: linear-gradient(99deg, #e7e0ff 0%, #c2b1fb 100%) !important;

      @include mq($to: xxl) {
        padding-right: 30px !important;
      }

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__image {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;

        @include mq($to: xxl) {
          top: unset !important;
          bottom: 0 !important;

          // bottom: -15%;
        }

        @include mq($to: xl) {
          bottom: -15% !important;
        }

        @include mq($to: lg) {
          bottom: 0 !important;
          width: 221.61px !important;
          height: auto !important;
        }

        @include mq($to: 865px) {
          right: -35px !important;
          bottom: -35px !important;
        }

        @include mq($to: 830px) {
          right: 0 !important;
          bottom: -11px !important;
          width: 260px !important;
        }

        @include mq($to: sm) {
          width: 230px !important;
        }
      }
    }

    &--franchising {
      padding-right: 341px !important;
      width: calc(60% - 10px) !important;
      background: linear-gradient(90deg, #f1f3f8 0%, #e8ebf1 100%) !important;

      @include mq($to: xxl) {
        padding-right: 30px !important;
        width: calc(50% - 9px) !important;
      }

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__image {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;

        @include mq($to: xxl) {
          top: unset !important;
          bottom: -20px !important;
        }

        @include mq($to: xl) {
          bottom: -15% !important;
        }

        @include mq($to: 865px) {
          bottom: -17% !important;
        }

        @include mq($to: 830px) {
          bottom: -15% !important;
        }

        @include mq($to: md) {
          bottom: -23% !important;
          width: 333px !important;
          height: 333px !important;
        }
      }
    }

    &--startups {
      background: $black-200 !important;
      padding-right: 237px !important;

      @include mq($to: xxl) {
        padding-right: 30px !important;
      }

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__image {
        position: absolute !important;
        right: 0 !important;
        bottom: 0 !important;

        @include mq($to: 830px) {
          bottom: 0 !important;
        }

        @include mq($to: sm) {
          right: unset !important;
          bottom: -27% !important;
          left: 50% !important;
          width: 311px !important;
          height: 311px !important;
          transform: translateX(-50%) !important;
        }
      }
    }

    &--deliveries {
      background: linear-gradient(99deg, #cfffc0 0%, #99f07d 100%) !important;
      width: 100% !important;
      padding-right: 453px !important;

      @include mq($to: 830px) {
        width: 100% !important;
      }

      &__descr {
        max-width: 838px !important;
      }

      &__image {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;

        @include mq($to: lg) {
          top: 30% !important;
          width: 100% !important;
          min-width: 358px !important;
          max-width: 358px !important;
          min-height: 358px !important;
          max-height: 358px !important;
        }

        @include mq($to: lg) {
          right: unset !important;
          left: 50% !important;
          width: 312px !important;
          height: 312px !important;
          transform: translateX(-50%) !important;
        }
      }

      @include mq($to: lg) {
        width: 100% !important;
        height: 346px !important;
        padding-right: 30px !important;
      }
    }
  }
}
