@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
// !!!!!!!!!!!! Этот код с ошибками, не берите его за пример (компонент использовать можно, писать подобным образом другие компоненты - нет), пишите в личку за объяснениями

.section {
  padding: 80px 0;

  @include mq($to: xxl) {
    padding: 60px 0;
  }

  @include mq($to: md) {
    padding: 20px 0;
  }

  &__title {
  }

  &__header {
    margin-bottom: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 80px;

    @include mq($to: xxl) {
      margin-bottom: 30px;
    }

    @include mq($to: md) {
      gap: 20px;
    }

    @include mq($to: sm) {
      flex-direction: column;
      gap: 0;
    }

    &__text_content {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
  }

  &__list {
    display: grid;
    margin: 0;
    padding: 0;
    list-style-type: none;
    grid-template-columns: 1fr 1fr;
    gap: 27px 60px;

    @include mq($to: xxl) {
      gap: 27px 26px;
    }

    @include mq($to: md) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      padding: 0 50px 27px 0;
      border-bottom: 1px solid $light-gray-100;
      gap: 9px;

      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }

      @include mq($to: md) {
        &:nth-last-child(-n + 2) {
          border-bottom: 1px solid $light-gray-100;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      @include mq($to: sm) {
        gap: 7px;
        padding: 0 0 20px;
      }
    }
  }

  &__our_team {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 18px 0;

    &__list {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;

      &_item {
        width: 33px;
        height: 33px;
        overflow: hidden;
        border-radius: 50%;
        outline: 3px solid $white-100;

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &:first-child {
          transform: translateX(-6px);
        }

        &:nth-child(2) {
          transform: translateX(-12px);
        }

        &:nth-child(3) {
          transform: translateX(-18px);
        }
      }
    }
  }
}
