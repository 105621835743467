@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.section {
  position: relative !important;
  padding-top: 46.5px !important;

  &::after {
    content: '' !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 100% !important;
    max-width: 526.704px !important;
    height: 100% !important;
    max-height: 526.704px !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 526.704px !important;
    background: $green-100 !important;
    filter: blur(175px) !important;

    @include mq($to: lg) {
      top: 39% !important;
    }

    @include mq($to: sm) {
      top: 43% !important;
      width: 387.266px !important;
      height: 387.266px !important;
      border-radius: 387.266px !important;
      filter: blur(128.67px) !important;
    }
  }

  &__content {
    position: relative !important;
    z-index: 1 !important;
    overflow: hidden !important;
  }

  &__title {
    display: -webkit-box;
    padding: 0;
    overflow: hidden;
    color: $black-200;
    text-align: center;
    font-family: $font-family--headings-narrow;
    font-weight: 600;
    letter-spacing: -4.32px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 80%;
    vertical-align: middle;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include fp(font-size, 176, 211.5, map-get($breakpoints, xxl), map-get($breakpoints, xxxl));

    @include mq($to: xxl) {
      @include fp(font-size, 133, 176, map-get($breakpoints, lg), map-get($breakpoints, xxl));
    }

    @include mq($to: lg) {
      @include fp(font-size, 60, 132, map-get($breakpoints, sm), map-get($breakpoints, lg));

      letter-spacing: -2.16px;
    }

    @include mq($to: sm) {
      @include fp(font-size, 87, 119, 375, 500);

      -webkit-line-clamp: 3;
      line-height: 95%;
      letter-spacing: -0.96px;
    }

    &_digital {
      font-family: $font-family--headings !important;
      font-weight: 700 !important;
    }

    &_food {
      font-family: $font-family--headings !important;
      font-weight: 700 !important;
      color: $green-600 !important;
    }

    &_solutions {
      color: $purple-500;

      @include mq($to: sm) {
        @include fp(font-size, 97, 135, 375, 500);
      }
    }

    &_and {
      color: $purple-500 !important;
    }

    &_for,
    &_food,
    &_and,
    &_beverages {
      @include fp(font-size, 148, 178, map-get($breakpoints, xxl), map-get($breakpoints, xxxl));

      @include mq($to: xxl) {
        @include fp(font-size, 112, 148, map-get($breakpoints, lg), map-get($breakpoints, xxl));
      }

      @include mq($to: lg) {
        @include fp(font-size, 50, 112, map-get($breakpoints, sm), map-get($breakpoints, lg));

        letter-spacing: -2.16px;
      }

      @include mq($to: sm) {
        @include fp(font-size, 36, 49, 375, 500);

        letter-spacing: -0.96px;
        transform: translateY(-10%);
      }
    }
  }

  &__subtitle {
    display: block !important;
    @include mq($to: sm) {
      display: none !important;
    }
  }

  &__mobile_subtitle {
    display: none !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
    text-transform: uppercase !important;
    font-family: $font-family--headings-narrow !important;

    @include mq($to: sm) {
      display: block !important;

      & .section__title_for,
      .section__title_food,
      .section__title_and,
      .section__title_beverages {
        @include fp(font-size, 41, 56, 375, 500);
      }
    }
  }

  &__bottom_blocks {
    display: flex !important;
    justify-content: space-between !important;
    position: relative !important;

    @include mq($to: md) {
      justify-content: center !important;
    }
  }

  &__block {
    display: flex !important;
    flex-direction: column !important;
    gap: 83.5px !important;
    max-width: 325px !important;
    margin: 45.44px 0 189.5px !important;

    @include mq($to: lg) {
      margin: 45.44px 0 303.39px !important;
      gap: 31.65px !important;
    }

    @include mq($to: md) {
      display: none !important;
    }

    &--second {
      max-width: 300px !important;
      text-align: center !important;
    }

    &_text {
    }

    &_button {
      width: max-content !important;

      @include mq($to: sm) {
        justify-content: center !important;
        width: 100% !important;
      }
    }
  }

  &__mobile_block {
    display: none !important;
    flex-direction: column !important;
    gap: 14px !important;
    margin-top: 290px !important;

    & p {
      text-align: center !important;
    }

    @include mq($to: md) {
      display: flex !important;
      align-items: center !important;
      margin-top: 365px !important;
    }

    @include mq($to: sm) {
      display: flex !important;
      align-items: center !important;
      margin-top: 77% !important;
      gap: 10px !important;
    }

    @include mq($from: sm) {
      & p {
        width: 100% !important;
        max-width: 500px !important;
        font-size: 18px !important;
      }
    }
  }

  &__clutch {
    justify-content: flex-end !important;
  }

  &__burger_image {
    position: absolute !important;
    top: -35% !important;
    left: 50% !important;
    transform: translateX(-50%) rotate(-13deg) !important;
    aspect-ratio: 0.926 / 1 !important;

    // @include mq($to: xl) {
    //   top: 3%;
    //   width: 440px;
    // }

    @include mq($to: xl) {
      top: -15% !important;
      width: 540px !important;
    }

    @include mq($to: lg) {
      top: 0 !important;
      width: 485px !important;
      min-width: 485px !important;
    }

    @include mq($to: md) {
      top: -15% !important;
    }

    @include mq($to: md) {
      top: -12% !important;
    }

    @include mq($to: sm) {
      top: -12% !important;
      width: 100% !important;
      min-width: unset !important;
    }
  }

  &__bottom_block {
    display: flex !important;
    align-items: center !important;
    padding: 40px 50px !important;
    background-color: $green-500 !important;
    gap: 60px !important;
    border-radius: $border-radius-2xl !important;

    @include mq($to: md) {
      margin-top: 41px !important;
      flex-direction: column !important;
      gap: 10px !important;
      text-align: center !important;
    }

    &__title {
      flex-shrink: 0 !important;
    }
  }
}
