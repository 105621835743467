@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
/* stylelint-disable media-feature-range-notation */
.section {
  display: flex !important;
  flex-direction: column !important;
  padding: 40px 0 60px !important;
  gap: 30px !important;

  @include mq($to: md) {
    padding: 40px 0 !important;
  }

  @include mq($to: sm) {
    padding: 30px 0 !important;
  }

  &__title {
    text-align: center !important;

    @include mq($to: sm) {
      font-size: 18px !important;
    }
  }

  &__list {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 30px !important;
    margin: 0 !important;
    padding: 0 !important;

    @media only screen and (max-width: 1250px) {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 20px !important;
    }

    @include mq($to: md) {
      grid-template-columns: 1fr !important;
      gap: 12px !important;
    }

    &_item {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 161px !important;
      border: 2px solid $white-400 !important;
      border-radius: $border-radius-2m !important;
      background: $white-200 !important;

      & img {
        mix-blend-mode: luminosity !important;
      }
    }
  }
}
